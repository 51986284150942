<template>
  <div style="padding-bottom: 4.5%">
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-form>
        <div>
          <h1 class="mr-sm-4 header-tablepage">Beauty Passport Detail</h1>
        </div>
        <b-tabs class="mt-3">
          <b-tab
            title="Stamp Info"
            justified
            :active="controlTab == 1"
            @click="onTabChange(1)"
          >
            <div class="p-3 bg-white">
              <b-row>
                <b-col md="12">
                  <InputText
                    textFloat="Stamp Card Name"
                    placeholder="Name"
                    type="text"
                    name="name"
                    isRequired
                    v-model="form.name"
                    :v="$v.form.name"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <label class="label-text">
                    Valid From
                    <span class="text-danger">*</span>
                  </label>
                  <div class="input-container">
                    <datetime
                      :input-style="styleDatetime"
                      placeholder="Please Select Date"
                      type="datetime"
                      v-model="form.valid_from"
                      :min-datetime="dateFormat()"
                      format="dd/MM/yyyy HH:mm:ss"
                      value-zone="Asia/Bangkok"
                      ref="transferDateStart"
                    ></datetime>
                    <div
                      class="icon-container text-right"
                      @click="$refs.transferDateStart.isOpen = true"
                    >
                      <font-awesome-icon
                        icon="calendar-alt"
                        :class="
                          id == 0
                            ? 'pointer color-primary'
                            : 'cursor-default color-primary'
                        "
                        color="#B41BB4"
                      />
                    </div>
                  </div>
                  <div v-if="$v.form.valid_from">
                    <span
                      class="text-error"
                      v-if="!$v.form.valid_from.required && chkClickForm"
                      >Please select date time.</span
                    >
                  </div>
                </b-col>
                <b-col md="6">
                  <label class="label-text">
                    Valid To
                    <span class="text-danger">*</span>
                  </label>
                  <div class="input-container">
                    <datetime
                      :input-style="styleDatetime"
                      placeholder="Please Select Date"
                      type="datetime"
                      v-model="form.valid_to"
                      format="dd/MM/yyyy HH:mm:ss"
                      :min-datetime="dateFormat()"
                      value-zone="Asia/Bangkok"
                      ref="transferDateEnd"
                    ></datetime>
                    <div
                      class="icon-container text-right"
                      @click="$refs.transferDateEnd.isOpen = true"
                    >
                      <font-awesome-icon
                        icon="calendar-alt"
                        :class="
                          id == 0
                            ? 'pointer color-primary'
                            : 'cursor-default color-primary'
                        "
                        color="#B41BB4"
                      />
                    </div>
                  </div>
                  <div v-if="$v.form.valid_to">
                    <span
                      class="text-error"
                      v-if="!$v.form.valid_to.required && chkClickForm"
                      >Please select date time.</span
                    >
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col md="6">
                  <InputText
                    class="mb-4"
                    textFloat="Extend Date"
                    placeholder="Name"
                    type="number"
                    name="extend_date"
                    isRequired
                    v-model="form.extend_date"
                    :v="$v.form.extend_date"
                  />
                </b-col>
                <b-col md="6">
                  <label class="font-weight-bold main-label">
                    Please Select Product
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-group v-slot="{ ariaDescribedby }" class="py-2">
                    <b-form-radio-group
                      v-model="form.product_group_id"
                      :options="optionsproductSelected"
                      :aria-describedby="ariaDescribedby"
                      name="radios-stacked"
                      :disabled="
                        id !== '0'
                          ? form.is_redeem == 1
                            ? form.product_group_id == 1
                              ? true
                              : false
                            : false
                          : false
                      "
                    ></b-form-radio-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <div>
                <div v-if="selectProduct" class="panelborder">
                  <div class="title-panel">Select Group Product</div>
                  <b-container class="no-gutters bg-white">
                    <div class="py-3">
                      <TableProduct
                        :ProductList="ProductList"
                        :idList="idList"
                        :isBusy="isBusy"
                        :fields="fields"
                        @displayOnly="productDisplayOnly"
                        @select="(val) => (filterProduct.select_product = val)"
                        @delete="(val) => (filterProduct.delete_product = val)"
                        @searchProduct="searchProduct"
                        :isRedeem="form.is_redeem"
                        :id="id"
                        :productSelectedShowonly="productSelectedShowonly"
                      />
                      <b-row>
                        <b-col
                          cols="12"
                          md="6"
                          class="form-inline justify-content-center justify-content-sm-start"
                        >
                          <p class="mb-0 p-gray">
                            Showing {{ showing }} to {{ showingTo }} of
                            {{ rows }} entires
                          </p>
                        </b-col>
                        <b-col
                          cols="12"
                          md="6"
                          class="form-inline justify-content-center justify-content-sm-end mt-2 mt-sm-0"
                        >
                          <b-pagination
                            v-model="filterProduct.page"
                            :total-rows="rows"
                            :per-page="filterProduct.take"
                            @change="pagination"
                            first-text="First"
                            prev-text="Prev"
                            next-text="Next"
                            last-text="Last"
                            class="m-md-0"
                          ></b-pagination>
                        </b-col>
                      </b-row>
                    </div>
                  </b-container>
                </div>
              </div>
              <b-row :class="form.product_group_id === 2 ? 'pt-4' : ''">
                <b-col sm="6">
                  <label class="font-weight-bold main-label text-nowrap">
                    Minimum Spend Type
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-group class="pt-2 mb-0">
                    <b-form-radio-group
                      id="radio-type"
                      v-model="form.required_target_type"
                    >
                      <b-form-radio :value="3">Baht</b-form-radio>
                      <b-form-radio :value="2">Quantity</b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                  <span
                    class="text-error"
                    v-if="$v.form.required_target_type.$error && chkClickForm"
                    >Please select Minimum Spend Type.</span
                  >
                </b-col>
                <b-col sm="6">
                  <div>
                    <b-form-checkbox
                      id="checkbox-invalid"
                      class="checkbox-invalid"
                      v-model="form.invalid_after_redeem"
                    >
                      <span class="text-black">Invalid After Redeem</span>
                    </b-form-checkbox>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-tab>

          <b-tab
            title="Stamp Detail"
            :disabled="tabDisable"
            :active="controlTab == 2"
            v-if="!tabDisable"
            @click="onTabChange(2)"
          >
            <div
              class="p-3 bg-white"
              v-for="(item, index) in stampDetail"
              :key="index"
            >
              <StampCardDetailsPanel
                :ref="`stampcard_detail_${index}`"
                :stampDetail="item"
                @delete="deleteStampCard"
                :id="id"
                :isRedeem="form.is_redeem"
                @acceptStamp="acceptStamp"
                :cssChkboxEdit="chkSaveStampCss(item.number)"
                :chkClickFormStamp="chkClickFormStamp"
                :chkClickFormStampId="chkClickFormStampId"
                :validateImageList="validateImageList"
                :validateQuantityList="validateQuantityList"
                :morethan0ErrorList="morethan0ErrorList"
                :afterRedeem="form.invalid_after_redeem === 1 ? true : false"
                :formMain="form"
              />
            </div>
            <div class="text-center mt-3">
              <button
                class="btn-add-stamp"
                @click.prevent="addNewItems()"
                :disabled="
                  id !== '0'
                    ? form.invalid_after_redeem == 1
                      ? true
                      : form.is_redeem == 1
                      ? true
                      : false
                    : false
                "
              >
                Add New Stamp No.
              </button>
            </div>
          </b-tab>
        </b-tabs>
      </b-form>
      <div v-if="showStatus" class="no-gutters bg-white mt-3 py-2 px-3">
        <b-form-checkbox
          switch
          v-model="form.status"
          class="radio-active"
          size="lg"
          :value="1"
          :unchecked-value="0"
        >
          <span class="ml-2 main-label">{{
            form.status ? "Active" : "Inactive"
          }}</span>
        </b-form-checkbox>
      </div>
      <FooterAction @submit="checkForm" routePath="/stampcard" />

      <!-- <div class="footer-action">
        <b-col>
          <router-link to="/stampcard">
            <b-button class="btn-cancel btn-width">CANCEL</b-button>
          </router-link>
        </b-col>
        <b-col class="text-right">
          <b-button
            v-if="controlTab == 1"
            class="main-color btn-save"
            @click="checkForm"
          >
            Save
          </b-button>
          <b-button
            v-else
            class="main-color btn-save"
            @click="acceptStampCardList"
          >
            Save
          </b-button>
        </b-col>
      </div> -->
    </div>
    <ModalConfirm
      ref="ModalConfirm"
      :text="respMsg"
      @hideModal="confirmHideModal"
    />
    <ModalAlertError
      ref="alertModal"
      :showModal="showModal"
      :text="errorMsg"
      @hideModal="errorHideModal"
    />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import TableProduct from "@/components/stampCard/TableProduct";
import { required } from "vuelidate/lib/validators";
import StampCardDetailsPanel from "@/components/stampCard/detail/StampCardDetailsPanel";
import OtherLoading from "@/components/loading/OtherLoading";
import FooterAction from "@/components/FooterAction";
import ModalAlertError from "@/components/coupon/modal/ModalAlertError";
import ModalConfirm from "@/components/coupon/modal/ModalConfirm";

export default {
  name: "StampCardDetails",
  components: {
    InputText,
    TableProduct,
    StampCardDetailsPanel,
    OtherLoading,
    FooterAction,
    ModalAlertError,
    ModalConfirm,
  },
  data() {
    return {
      id: this.$route.params.id,
      isDisable: false,
      isBusy: false,
      rows: 0,
      showing: 1,
      showingTo: 5,
      cardNumber: 1,
      cardselected: "",
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      form: {
        name: "",
        valid_from: "",
        valid_to: "",
        extend_date: 0,
        status: 0,
        product_group_id: 1,
        invalid_after_redeem: 0,
        required_target_type: 3,
      },
      idList: [],
      productSelected: 1,
      optionsproductSelected: [
        { text: "All", value: 1 },
        { text: "Product", value: 2 },
      ],
      selectProduct: false,
      stampDetail: [
        {
          number: 1,
          header_text: "",
          sub_header_text: "",
          description: "",
          required_target_type: "",
          quantity: null,
          image_url: "",
        },
      ],
      fields: [
        {
          key: "isCheck",
          label: "",
        },
        {
          key: "name",
          label: "Product Name",
          class: "w-200",
        },
        {
          key: "article_no",
          label: "SKU",
          class: "w-200",
        },
        {
          key: "price",
          label: "Price",
          class: "w-100px",
        },
      ],
      ProductList: [],
      filterProduct: {
        show_only_select: 0,
        select_product: [],
        delete_product: [],
        search: "",
        page: 1,
        take: 5,
      },
      isLoading: true,
      chkClickForm: false,
      stampCardIdInfo: 0,
      showModal: false,
      errorMsg: "",
      stampcard_detail: [],
      cssChkboxEdit: "color-chkbox",
      chkClickFormStamp: false,
      chkClickFormStampId: 0,
      respMsg: "",
      saveStampid: [],
      tabDisable: true,
      btnDisabled: false,
      controlTab: isNaN(parseInt(this.$route.query.tab))
        ? 1
        : parseInt(this.$route.query.tab),
      defaultProductGroupByid: 1,
      defaultSelectProductUpdate: [],
      Invalid_after_redeem: false,
      deleteStampDetail: false,
      deleteStampDetailArry: [],
      clickBtnAddNewItem: false,
      validateImageList: false,
      validateQuantityList: false,
      stampCardIdUpdate: 0,
      defaultStampDetail: [],
      dataUpdate: [],
      dataCreate: [],
      deleteBeforeCreate: [],
      dataBeforCreateItem: 0,
      productSelectedShowonly: false,
      morethan0ErrorList: false,
      showStatus: true,
    };
  },
  validations: {
    form: {
      name: { required },
      valid_from: { required },
      valid_to: { required },
      extend_date: { required },
      required_target_type: { required },
    },
  },
  watch: {
    controlTab(val) {
      if (val === 2) {
        this.showStatus = false;
      } else {
        this.showStatus = true;
      }
    },
    "form.product_group_id": function (val) {
      if (val === 1) {
        this.selectProduct = false;
      } else {
        this.selectProduct = true;
      }
    },
  },
  created: async function () {
    if (this.id !== "0") {
      await this.getDatas();
      if (this.form.product_group_id !== 1) {
        this.filterProduct.show_only_select = 1;
        await this.getProductGroup();
      } else {
        await this.getProductGroup();
      }
    } else {
      await this.getProductGroup();
    }
  },
  methods: {
    onTabChange(val) {
      if (val == 1) {
        this.showStatus = true;
        if (this.form.product_group_id !== 1) {
          this.filterProduct.show_only_select = 1;
          this.filterProduct.page = 1;
          this.productSelectedShowonly = true;
          this.getProductGroup();
        } else {
          this.productSelectedShowonly = false;
        }
      } else {
        this.showStatus = false;
        this.productSelectedShowonly = false;
      }
    },
    chkSaveStampCss(number) {
      if (this.saveStampid?.length > 0) {
        if (this.saveStampid.includes(number)) {
          return "color-chkbox-edit";
        } else {
          return "color-chkbox";
        }
      } else {
        return "color-chkbox";
      }
    },
    async getProductGroup() {
      this.isBusy = true;
      let reqData = {
        id: this.id,
        form: this.filterProduct,
      };
      await this.$store.dispatch("getProductList", reqData);
      let data = this.$store.state.stampcard.productList;
      if (data.result == 1) {
        this.isBusy = false;
        this.isLoading = false;
        this.ProductList = data.detail.detail;
        this.rows = data.detail.total_count;
        if (this.id !== "0") {
          this.defaultSelectProductUpdate = data.detail.productSelect;
        }
      } else {
        this.isLoading = false;
      }
    },
    deleteStampCard(number, id) {
      const result = this.stampDetail
        .map(function (e) {
          return e.number;
        })
        .indexOf(number);
      this.stampDetail.splice(result, 1);
      for (let i in this.stampDetail) {
        this.stampDetail[i].number = i++ + 1;
      }
      this.deleteStampDetail = true;
      if (this.id !== "0") {
        if (id !== undefined) {
          this.isLoading = true;
          for (const i in this.stampDetail) {
            this.deleteStampDetailArry.push({
              id: this.stampDetail[i].id,
              number: this.stampDetail[i].number,
            });
          }
          this.deleteStampCardList(id);
        }
      }
    },
    addNewItems() {
      let no = this.cardNumber++;
      if (this.id !== "0") {
        this.clickBtnAddNewItem = true;
        this.stampDetail.push({ number: no, createOrupdate: true });
      } else {
        this.stampDetail.push({ number: no });
      }
      if (this.stampDetail?.length > 0) {
        for (let i in this.stampDetail) {
          if (this.stampDetail[i].number !== i++ + 1) {
            for (let j in this.stampDetail) {
              this.stampDetail[j].number = j++ + 1;
            }
          }
        }
      }
    },
    getDatas: async function () {
      this.isLoading = true;
      await this.$store.dispatch("getStampCardById", this.id);
      let data = this.$store.state.stampcard.stampCardDetail;
      if (data.result == 1) {
        this.isLoading = false;
        this.form = data.detail.detail;
        if (this.form.stampcardDetail?.length > 0) {
          this.stampDetail = this.form.stampcardDetail;
        }
        this.defaultProductGroupByid = this.form.product_group_id;
        this.form.product_group_id = this.form.product_group_id == 1 ? 1 : 2;
        this.filterProduct.show_only_select = 1;
        this.form.invalid_after_redeem =
          this.form.invalid_after_redeem == 0 ? false : true;
        this.tabDisable = false;
      } else {
        this.isLoading = false;
      }
    },
    checkForm: async function () {
      if (this.controlTab == 1) {
        this.$v.form.$touch();
        if (!this.form.valid_from) {
          this.chkClickForm = true;
        } else if (!this.form.valid_to) {
          this.chkClickForm = true;
        } else {
          this.chkClickForm = false;
        }
        if (this.$v.form.$error) {
          return;
        }
        this.isLoading = true;
        this.filterProduct.select_product = Array.from(
          new Set(this.filterProduct.select_product)
        );
        if (this.id === "0") {
          if (this.stampCardIdInfo === 0) {
            let reqData = {
              name: this.form.name,
              select_product:
                this.form.product_group_id == 1
                  ? []
                  : this.filterProduct.select_product,
              valid_from: this.form.valid_from,
              valid_to: this.form.valid_to,
              extend_date: this.form.extend_date,
              status: this.form.status ? 1 : 0,
              invalid_after_redeem: this.form.invalid_after_redeem ? 1 : 0,
              required_target_type: this.form.required_target_type,
            };
            await this.$store.dispatch("createStampCardInfo", reqData);
            let data = this.$store.state.stampcard.respCreateStampInfo;
            if (data.result == 1) {
              this.stampCardIdInfo = data.detail.id;
              this.defaultProductGroupByid = data.detail.product_group_id;
              this.isLoading = false;
              this.tabDisable = false;
              this.controlTab = 2;
            } else {
              this.showModal = true;
              this.errorMsg = data.message;
            }
          } else {
            this.updateStampCardInfo(this.stampCardIdInfo);
          }
        } else {
          this.updateStampCardInfo(this.id);
        }
      }else{
        this.acceptStampCardList()
      }
    },
    async updateStampCardInfo(id) {
      this.filterProduct.delete_product = Array.from(
        new Set(this.filterProduct.delete_product)
      );
      if (this.form.product_group_id == 1) {
        if (this.filterProduct.select_product.length > 0) {
          this.filterProduct.delete_product = this.filterProduct.select_product;
          this.filterProduct.select_product = [];
        }
        if (this.defaultSelectProductUpdate.length > 0) {
          this.filterProduct.delete_product = this.defaultSelectProductUpdate;
          this.filterProduct.select_product = [];
        }
      } else {
        if (this.filterProduct.select_product.length > 0) {
          this.filterProduct.select_product = this.filterProduct.select_product;
        } else {
          this.filterProduct.select_product = this.defaultSelectProductUpdate;
        }
      }
      let reqData = {
        id: id,
        form: {
          product_group_id:
            this.form.product_group_id == 1
              ? this.form.product_group_id
              : this.defaultProductGroupByid,
          delete_product: this.filterProduct.delete_product,
          name: this.form.name,
          select_product: this.filterProduct.select_product,
          valid_from: this.form.valid_from,
          valid_to: this.form.valid_to,
          extend_date: this.form.extend_date,
          status: this.form.status ? 1 : 0,
          invalid_after_redeem: this.form.invalid_after_redeem ? 1 : 0,
          required_target_type: this.form.required_target_type,
        },
      };
      await this.$store.dispatch("updateStampCardInfo", reqData);
      let data = this.$store.state.stampcard.respUpdateStampInfo;
      if (data.result == 1) {
        // this.stampCardIdInfo = this.id;
        this.respMsg = data.message;
        this.getDatas();
        this.isLoading = false;
        this.controlTab = 2;
      } else {
        this.showModal = true;
        this.errorMsg = data.message;
      }
    },
    pagination(value) {
      this.filterProduct.page = value;
      if (value === 1) {
        this.showing = this.filterProduct.page;
        this.showingTo = this.filterProduct.take;
      } else {
        this.showing =
          value * this.filterProduct.take - this.filterProduct.take;
        this.showingTo = this.showing + this.filterProduct.take;
      }
      this.getProductGroup();
    },
    productDisplayOnly(val, page) {
      this.filterProduct.show_only_select = val;
      this.filterProduct.page = page;
      this.getProductGroup();
    },
    searchProduct(val) {
      this.filterProduct.search = val;
      this.getProductGroup();
    },
    dateFormat() {
      let today = new Date();
      return this.$moment(today).format();
    },
    async acceptStamp(val) {
      this.isLoading = true;
      if (this.id === "0") {
        let reqData = {
          id: this.stampCardIdInfo,
          form: val,
        };
        await this.$store.dispatch("createStampCardDetail", reqData);
        let data = this.$store.state.stampcard.respCreateStampDetail;
        if (data.result == 1) {
          this.respMsg = data.message;
          this.saveStampid.push(val.number);
          this.isLoading = false;
          this.$swal(data.message, {
            icon: "success",
          });
          this.controlTab = 2;
        } else {
          this.isLoading = false;
          this.showModal = true;
          this.errorMsg = data.message;
        }
      } else {
        await this.$store.dispatch("getStampCardById", this.id);
        const beforeDataStamp =
          this.$store.state.stampcard.stampCardDetail.detail.detail
            .stampcardDetail;
        val.required_target_type = parseInt(val.required_target_type);
        if (!val.createOrupdate && beforeDataStamp.length > 0) {
          let reqData = {
            id: this.id,
            form: val,
          };
          await this.$store.dispatch("updateStampCardDetail", reqData);
          let data = this.$store.state.stampcard.respUpdateStampCardDetail;
          if (data.result == 1) {
            this.saveStampid.push(val.number);
            this.isLoading = false;
            this.$swal(data.message, {
              icon: "success",
            });
            this.controlTab = 2;
          } else {
            this.isLoading = false;
            this.showModal = true;
            this.errorMsg = data.message;
          }
        } else {
          let reqData = {
            id: this.id,
            form: {
              description: val.description,
              header_text: val.header_text,
              number: val.number,
              quantity: val.quantity,
              required_target_type: val.required_target_type,
              sub_header_text: val.sub_header_text,
              image_url: val.image_url,
            },
          };
          await this.$store.dispatch("createStampCardDetail", reqData);
          let data = this.$store.state.stampcard.respCreateStampDetail;
          if (data.result == 1) {
            this.respMsg = data.message;
            this.saveStampid.push(val.number);
            this.getDatas();
            this.isLoading = false;
            this.$swal(data.message, {
              icon: "success",
            });
            this.controlTab = 2;
          } else {
            this.isLoading = false;
            this.showModal = true;
            this.errorMsg = data.message;
          }
        }
      }
    },
    async acceptStampCardList() {
      let isError = false;
      if (this.id !== "0") {
        await this.$store.dispatch("getStampCardById", this.id);
        this.defaultStampDetail =
          this.$store.state.stampcard.stampCardDetail.detail.detail.stampcardDetail;
      }
      for (let i in this.stampDetail) {
        this.chkClickFormStampId =
          this.$refs[`stampcard_detail_${i}`][0].stampDetail.number;
        // check validate type image
        if (
          this.$refs[`stampcard_detail_${i}`][0].stampDetail
            .required_target_type
        ) {
          if (
            this.$refs[`stampcard_detail_${i}`][0].stampDetail
              .required_target_type === 1
          ) {
            if (!this.$refs[`stampcard_detail_${i}`][0].stampDetail.image_url) {
              this.$refs[`stampcard_detail_${i}`][0].$v.stampDetail.$touch();
              if (
                this.$refs[`stampcard_detail_${i}`][0].$v.stampDetail.$error
              ) {
                isError = true;
              }
            }
          } else {
            this.$refs[`stampcard_detail_${i}`][0].$v.stampDetail.$touch();
            if (this.$refs[`stampcard_detail_${i}`][0].$v.stampDetail.$error) {
              isError = true;
            }
          }
        } else {
          this.$refs[`stampcard_detail_${i}`][0].$v.stampDetail.$touch();
          if (this.$refs[`stampcard_detail_${i}`][0].$v.stampDetail.$error) {
            isError = true;
          }
        }

        if (!isError) {
          if (this.$refs[`stampcard_detail_${i}`][0].stampDetail.quantity) {
            this.$refs[`stampcard_detail_${i}`][0].stampDetail.quantity =
              parseInt(
                this.$refs[`stampcard_detail_${i}`][0].stampDetail.quantity
              );
          } else {
            this.$refs[`stampcard_detail_${i}`][0].stampDetail.quantity = 0;
          }
          if (
            !this.saveStampid.includes(
              this.$refs[`stampcard_detail_${i}`][0].stampDetail.number
            )
          ) {
            if (
              !this.stampcard_detail.find(
                (el) =>
                  el.number ==
                  this.$refs[`stampcard_detail_${i}`][0].stampDetail.number
              )
            ) {
              this.stampcard_detail.push(
                this.$refs[`stampcard_detail_${i}`][0].stampDetail
              );
            }
          } else {
            if (
              this.$refs[`stampcard_detail_${i}`][0].stampDetail.createOrupdate
            ) {
              if (
                !this.stampcard_detail.find(
                  (el) =>
                    el.number ==
                    this.$refs[`stampcard_detail_${i}`][0].stampDetail.number
                )
              ) {
                this.stampcard_detail.push(
                  this.$refs[`stampcard_detail_${i}`][0].stampDetail
                );
              }
            }
          }
        }
      }
      if (!isError) {
        if (!this.morethan0ErrorList) {
          if (!this.validateQuantityList) {
            if (this.id === "0") {
              let reqData = {
                id: this.stampCardIdInfo,
                form: { stampcard_detail: this.stampcard_detail },
              };
              this.isLoading = true;
              await this.$store.dispatch("createStampCardList", reqData);
              let data = this.$store.state.stampcard.respCreateStampDetailList;
              if (data.result === 1) {
                this.$swal("Create Success!", {
                  icon: "success",
                }).then(function () {
                  window.location.href = `/stampcard`;
                });
              } else {
                this.isLoading = false;
                this.showModal = true;
                this.errorMsg = data.message;
              }
            } else {
              if (this.defaultStampDetail?.length > 0) {
                for (const i in this.stampcard_detail) {
                  if (!this.stampcard_detail[i].createOrupdate) {
                    this.dataUpdate.push(this.stampcard_detail[i]);
                  } else {
                    this.dataCreate.push(this.stampcard_detail[i]);
                  }
                }
              } else {
                this.createListFirst();
              }
            }
            if (this.id !== "0" && this.defaultStampDetail?.length > 0) {
              this.updateListStamp();
            }
          }
        }
      }
    },
    async createListFirst() {
      this.isLoading = true;
      let reqData = {
        id: this.id,
        form: { stampcard_detail: this.stampcard_detail },
      };
      await this.$store.dispatch("createStampCardList", reqData);
      let data = this.$store.state.stampcard.respCreateStampDetailList;
      if (data.result === 1) {
        this.$swal("Create Success!", {
          icon: "success",
        }).then(function () {
          window.location.href = `/stampcard`;
        });
      } else {
        this.isLoading = false;
        this.showModal = true;
        this.errorMsg = data.message;
      }
    },
    async createListStamp() {
      this.isLoading = true;
      let reqData = {
        id: this.id,
        form: { stampcard_detail: this.dataCreate },
      };
      await this.$store.dispatch("createStampCardList", reqData);
      let data = this.$store.state.stampcard.respCreateStampDetailList;
      if (data.result === 1) {
        this.$swal("Create Success!", {
          icon: "success",
        }).then(function () {
          window.location.href = `/stampcard`;
        });
      } else {
        this.isLoading = false;
        this.showModal = true;
        this.errorMsg = data.message;
      }
    },
    async updateListStamp() {
      this.isLoading = true;
      let reqData = {
        id: this.id,
        form: { stampcard_detail: this.dataUpdate },
      };
      await this.$store.dispatch("updateStampCardList", reqData);
      let data = this.$store.state.stampcard.respUpdateStampCardDetailList;
      if (data.result == 1) {
        if (this.dataCreate.length > 0) {
          this.createListStamp();
        } else {
          this.$swal("Update Success!", {
            icon: "success",
          });
          this.isLoading = false;
          this.controlTab = 2;
        }
      } else {
        this.isLoading = false;
        this.showModal = true;
        this.errorMsg = data.message;
      }
    },
    async deleteStampCardList(deleteId) {
      this.deleteStampDetail = false;
      let requstData = {
        stampcard_detail: this.deleteStampDetailArry,
        stampcard_detail_id: parseInt(deleteId),
      };
      await this.$store.dispatch("deleteStampCardDetail", requstData);
      let data = this.$store.state.stampcard.respDeleteStampCardDetail;
      if (data.result == 1) {
        window.location.href = `/stampcard/details/${this.id}`;
      } else {
        this.isLoading = false;
        this.showModal = true;
        this.errorMsg = data.message;
      }
    },
    errorHideModal() {
      this.showModal = false;
    },
    confirmHideModal() {
      window.location.href = `/stampcard/details/${this.id}`;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vdatetime-popup {
  top: 60% !important;
}
.btn-add-stamp {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  border-radius: 2px;
  width: 50%;
  padding: 4px 0;
}
::v-deep .nav-tabs .nav-link:not(.active):hover {
  background-color: transparent;
  color: var(--primary-color);
  border-bottom: 4px solid var(--primary-color);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border-bottom: 4px solid var(--primary-color) !important;
}
::v-deep .card {
  background-color: #f8f8f8;
}
button:disabled,
button[disabled] {
  background-color: #f39ab2;
}
::v-deep .date-picker .vdatetime-input {
  width: 100%;
  color: var(--primary-color);
  border: 1px solid #d8dbe0;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 15px;
}
.input-container {
  display: flex;
  padding: 5px 10px;
  border: 1px solid #d8dbe0;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;
}
.text-black {
  color: #000;
}
@media (min-width: 350px) and (max-width: 991px) {
  .footer-action {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.footer-action {
  margin-left: var(--margin-spacing);
  z-index: 2;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background: #fff;
  display: flex;
  flex-direction: row;
  transition: margin-left 0.25s, margin-right 0.25s, width 0.25s, flex 0.25s;
}
.panelborder {
  border: 1px solid #d8dbe0;
}
.checkbox-invalid {
  padding-top: 30px;
}
</style>
