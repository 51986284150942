<template>
  <div style="padding-bottom: 4.5%">
    <b-card v-if="stampDetail">
      <template #header>
        <div>
          <font-awesome-icon :icon="['fas', 'check-circle']" :class="cssChkboxEdit"/>
          <span class="pl-2">No. {{ stampDetail.number }}</span>
        </div>
      </template>
      <b-row>
        <b-col md="6">
          <InputText
            textFloat="Header Text"
            placeholder="Header Text"
            type="text"
            name="headertext"
            v-model="stampDetail.header_text"
          />
        </b-col>
        <b-col md="6">
          <InputText
            textFloat="Sub Header Text"
            placeholder="Sub Header Text"
            type="text"
            name="subheadertext"
            v-model="stampDetail.sub_header_text"
          />
        </b-col>
        <b-col md="12">
          <InputTextArea
            :rows="4"
            isRequired
            textFloat="Description"
            placeholder="Description"
            id="textarea-rows"
            v-model="stampDetail.description"
            :v="$v.stampDetail.description"
          />
        </b-col>
        <b-col md="6">
          <label class="font-weight-bold main-label">
            Minimum spend type
            <span class="text-danger">*</span>
          </label>
          <b-form-group v-if="formMain.required_target_type == 2">
            <b-form-radio-group v-model="stampDetail.required_target_type">
              <b-form-radio
                v-for="(items, i) in optionsminimumSpendType1"
                :key="i"
                :value="items.value"
                :disabled="isDisable()"
                >{{ items.text }}
                </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
          <b-form-group v-if="formMain.required_target_type == 3">
            <b-form-radio-group v-model="stampDetail.required_target_type">
              <b-form-radio
                v-for="(items, i) in optionsminimumSpendType2"
                :key="i"
                :value="items.value"
                :disabled="isDisable()"
                >{{ items.text }}
                </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
          <div v-if="$v.stampDetail.required_target_type.$error">
            <span class="text-error">Please select Minimum Spend Type </span>
          </div>
          <!-- <div v-if="chkClickFormStamp">
            <span class="text-error">Please select Minimum spend type</span>
          </div> -->
        </b-col>
        <b-col>
          <div>
            <div
              v-if="
                stampDetail.required_target_type &&
                stampDetail.required_target_type === 1
              "
            >
              <UploadFile
                textFloat="Cover Image"
                placeholder="Please choose file"
                format="all"
                :fileName="stampDetail.image_url"
                v-model="stampDetail.image_url"
                name="thumbnail"
                text="*Please upload only file .png, .jpg with ratio 1:1"
                :accept="acceptType"
                v-on:onFileChange="onImageChange"
                v-on:delete="deleteImage"
                :v="$v.stampDetail.image_url"
              />
              <div>
                <div
                  cols="8"
                  class="img-box-preview b-contain mb-3"
                  v-bind:style="{
                    'background-image': 'url(' + showPreview + ')',
                  }"
                >
                  <img
                    src="@/assets/icons/loading.svg"
                    class="loading"
                    alt="loading"
                    v-if="isLoadingImage"
                  />
                  <div class="text-right">
                    <font-awesome-icon
                      icon="times-circle"
                      class="text-secondary delete-icon pointer"
                      v-if="stampDetail.image_url"
                      @click="deleteImage"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <InputText
                textFloat="Minimum spend"
                placeholder="Minimum spend"
                type="number"
                oninput="if( this.value.length > 5 )  this.value = this.value.slice(0,5)"
                :disabled="isDisable()"
                @onDataChange="onDataChangeQuantity"
                v-model="stampDetail.quantity"
                inputmode="numeric"
                :v="$v.stampDetail.quantity"
              />
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-button
            v-if="id === '0'"
            class="border-btn"
            :disabled="isDisable()"
            @click="deleteItem(stampDetail.number,0)"
            >Delete</b-button
          >
          <b-button
            v-else
            class="border-btn"
            :disabled="isDisable()"
            @click="deleteItem(stampDetail.number,stampDetail.id)"
            >Delete</b-button
          >
        </b-col>
        <b-col cols="6" class="text-right ">
          <b-button @click="acceptStampCard" class="submit-btn">
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <ModalConfirm ref="confirmModal" :text="confirmMsg" @handler="deleteStampCard"/>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputTextArea from "@/components/inputs/InputTextArea";
import { required , requiredIf } from "vuelidate/lib/validators";
import ModalConfirm from "@/components/modal/ModalConfirm";
import UploadFile from "@/components/inputs/UploadFile";
export default {
  props: {
    stampDetail: {
      required: true,
      type: Object,
    },
    id:{
      required:true,
      type:String
    },
    isRedeem:{
      required:false,
      type:Number
    },
    cssChkboxEdit:{
      required:false,
      type:String
    },
    chkClickFormStamp:{
      required:true,
      type:Boolean
    },
    chkClickFormStampId:{
      required:true,
      type:Number
    },
    validateImageList:{
      required:false,
      type:Boolean
    },
    validateQuantityList:{
      required:false,
      type:Boolean
    },
    morethan0ErrorList:{
      required:false,
      type:Boolean
    },
    v:{
      required:false,
      type:Object
    },
    afterRedeem:{
      required:false,
      type:Boolean
    },
    formMain:{
      required:true,
      type:Object
    }
  },
  components: {
    InputText,
    UploadFile,
    InputTextArea,
    ModalConfirm
  },
  validations: {
    stampDetail: {
      description: { required },
      required_target_type: {
        required : requiredIf(function(item){
          if(!item.required_target_type){
            return true
          }else{
            return false
          }
        })
      },
      quantity: {
        required : requiredIf(function (item) {
          if(item.required_target_type){
            if(parseInt(item.required_target_type) === 2 || parseInt(item.required_target_type) === 3){
              return true;
            }else if(parseInt(item.required_target_type) === 0){
              return true;
            }else{
              return false;
            }
          }else{
            if(!item.quantity){
              if(item.quantity === 0){
                return true;
              }else{
                return true;
              }
            }else{
              return false;
            }
          }
        })
      },
      image_url: {
        required : requiredIf(function (item) {
          return item.required_target_type === 1 && !item.image_url;
        }),
      },
    }
  },
  data() {
    return {
      form: {
        name : "",
        valid_from : "",
        valid_to : "",
        extend_date : 0,
        status : 0,
        product_group_id : 1,
        stampcard_detail: {
          number: 1,
          header_text: "",
          sub_header_text: null,
          description: "",
          required_target_type: "0",
          quantity: 0,
          image_url: null
        },
      },
      cardselected: "",
      optionsminimumSpendType1: [
        { text: "Quantity", value: 2 },
        { text: "Reward", value: 1 },
      ],
      optionsminimumSpendType2: [
        { text: "Baht", value: 3 },
        { text: "Reward", value: 1 },
      ],
      spendTypeReward: false,
      arryGroupReward: [],
      acceptType: "image/jpeg, image/png",
      showPreview: "",
      isLoadingImage: false,
      image: {
        height: "",
        width: "",
      },
      selected : "",
      isDisabled : false,
      chkClickFormStamp2 : this.chkClickFormStamp,
      validateImage : false,
      validateQuantity: false,
      confirmMsg:"",
      numberStamp: 0,
      idStamp : 0,
      morethan0Error : false
    };
  },
  created(){
    if(this.id !== '0' && this.stampDetail.image_url){
      this.showPreview = this.stampDetail.image_url;
    }
  },
  mounted(){
    if(this.id !== '0' && this.stampDetail.image_url){
      this.showPreview = this.stampDetail.image_url;
    }
  },
  methods: {
    deleteStampCard(val){
      if(val){
        this.$emit("delete", this.numberStamp,this.idStamp);
      }
    },
    onDataChangeQuantity(val){
      this.stampDetail.quantity = val;
    },
    onChangeSpendType(evt, id) {
      this.stampDetail.required_target_type = parseInt(evt.slice(-1));
    },
    onImageChange(val) {
      this.isLoadingImage = true;
      this.isDisabled = true;
      var reader = new FileReader();
      reader.readAsDataURL(val);
      reader.onload = async () => {
        let img = new Image();
        img.onload = () => {
          this.image.width = img.width;
          this.image.height = img.height;
          if (this.image.width !== this.image.height) {
              this.$swal("Please upload image with ratio 1:1 !", {
                icon: "info",
              })
              this.deleteImage();
              this.isLoadingImage = false;
              this.isDisabled = false;
          } else {
            this.setDataImage(reader.result);
          }
        };
        img.src = await reader.result;
      };
    },
    deleteImage() {
      this.stampDetail.image_url = null;
      this.showPreview = null;
    },
    async setDataImage(base64) {
      let url = await this.saveImagetoDb(base64);
      this.isDisabled = false;
      this.showPreview = url;
      this.stampDetail.image_url = url;
      this.isLoadingImage = false;
      this.$v.stampDetail.$reset();
      this.stampDetail.required_target_type = 1;
    },
    saveImagetoDb: async function (img) {
      let form = {
        Base64String: img,
      };
      await this.$store.dispatch('ActionUploadImage', form);
      let data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      return data.detail;
    },
    deleteItem(number,id) {
      this.confirmMsg = "ยืนยันการลบ?";
      this.$refs.confirmModal.show();
      this.numberStamp = number;
      this.idStamp = id;
    },
    isDisable(){
      if(this.id !== '0' && this.afterRedeem){
        if(this.afterRedeem){
          return true;
        }
        if(this.isRedeem == 1){
          return true;
        }
      }else{
        return false;
      }
    },
    acceptStampCard(){
      if(this.stampDetail.required_target_type){
        if(this.stampDetail.required_target_type === 1){
          if(!this.stampDetail.image_url){
            this.$v.stampDetail.$touch();
            if (this.$v.stampDetail.$error) {
              return;
            }
          }
        }else{
          this.$v.stampDetail.$touch();
          if (this.$v.stampDetail.$error) {
            return;
          }
        }
      }else{
        this.$v.stampDetail.$touch();
        if (this.$v.stampDetail.$error) {
          return;
        }
      }
      this.stampDetail.required_target_type = parseInt(this.stampDetail.required_target_type);
      if(this.stampDetail.required_target_type == 1 || this.stampDetail.required_target_type == '1'){
        this.stampDetail.quantity = 0;
        let reqData = {};
        if(!this.stampDetail.createOrupdate){
          reqData =
          {
            id:this.stampDetail.id,
            number:this.stampDetail.number,
            header_text:this.stampDetail.header_text == undefined ? "" : this.stampDetail.header_text,
            sub_header_text:this.stampDetail.sub_header_text == undefined ? "" : this.stampDetail.sub_header_text,
            description:this.stampDetail.description,
            image_url:this.stampDetail.image_url,
            required_target_type:this.stampDetail.required_target_type,
            quantity:this.stampDetail.quantity
          };
        }else{
          reqData =
          {
            createOrupdate:this.stampDetail.createOrupdate,
            number:this.stampDetail.number,
            header_text:this.stampDetail.header_text == undefined ? "" : this.stampDetail.header_text,
            sub_header_text:this.stampDetail.sub_header_text == undefined ? "" : this.stampDetail.sub_header_text,
            description:this.stampDetail.description,
            image_url:this.stampDetail.image_url,
            required_target_type:this.stampDetail.required_target_type,
            quantity:this.stampDetail.quantity
          };
        }
        this.$emit('acceptStamp',reqData);
      }else{
        this.stampDetail.image_url = null;
        this.stampDetail.quantity = parseInt(this.stampDetail.quantity);
        let reqData = {};
        if(!this.stampDetail.createOrupdate){
          reqData =
            {
              id:this.stampDetail.id,
              number:this.stampDetail.number,
              header_text:this.stampDetail.header_text == undefined ? "" : this.stampDetail.header_text,
              sub_header_text:this.stampDetail.sub_header_text == undefined ? "" : this.stampDetail.sub_header_text,
              description:this.stampDetail.description,
              image_url:this.stampDetail.image_url,
              required_target_type:this.stampDetail.required_target_type,
              quantity:this.stampDetail.quantity
            };
        }else{
          reqData =
          {
            createOrupdate:this.stampDetail.createOrupdate,
            number:this.stampDetail.number,
            header_text:this.stampDetail.header_text == undefined ? "" : this.stampDetail.header_text,
            sub_header_text:this.stampDetail.sub_header_text == undefined ? "" : this.stampDetail.sub_header_text,
            description:this.stampDetail.description,
            image_url:this.stampDetail.image_url,
            required_target_type:this.stampDetail.required_target_type,
            quantity:this.stampDetail.quantity
          };
        }
        this.$emit('acceptStamp',reqData);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.img-box-preview {
  width: 100%;
  padding-bottom: 96.3%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
  position: relative;
}
::v-deep .form-group {
  margin-bottom: 0;
}
.color-chkbox{
  color: #bfbfbf;
  font-size: 17px;
}
.color-chkbox-edit{
  color: var(--primary-color);
  font-size: 17px;
}
::v-deep .detail-format{
  height: auto;
}
</style>
